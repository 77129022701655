<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">FAMILY TRAVEL INSURANCE</h1>
      </div>
      <div class="box b-shdw-3">
				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>

				<div class="content">
					<h2>Family Travel Insurance</h2>
					<p class="mb-4">
						At Covered2go, family travel insurance covers one or two adults and all children (including foster children), aged under 18 (or under age 23, if in full time education), which are living with you permanently.
						<br><br>
						Both adults can travel independently, however, all insured children must travel with at least one of the insured adults.
					</p>

					<h2>What are the benefits of buying family travel insurance?</h2>
					<ul class="mb-4">
						<li>Having one policy covering the entire family makes things much simpler and is much more cost effective, rather than each of you having to purchase a separate policy. </li>
						<li>Annual policyholders can travel independently (without the kids), throughout the year too. </li>
					</ul>

					<h2>What does family travel insurance cover?</h2>
					<p class="mb-4">
						Family travel insurance will provide you with all the major benefits our policies provide cover for, such as:
					</p>
					<ul class="mb-4">
						<li>Cancellation, delays, and curtailment.</li>
						<li>Lost, stolen or damaged personal belongings.</li>
						<li>24-hour medical care which you or your family may need on your trip.</li>
						<li>Personal liability.</li>
					</ul>
					<p class="mb-4">
						It is essential that you purchase travel insurance for your family trip.
						<br><br>
						If someone were to fall and have an accident abroad, you would have to pay for the treatment yourself, if you did not have cover in place. Healthcare bills can spiral out of control, so we really urge you to take out travel insurance for your family holiday.
					</p>

					<h2>What types of family travel insurance are there?</h2>
					<p class="mb-4">
						There are two main types of policy to choose from, single trip and annual multi-trip. If you only plan to take one trip in a year, then a single policy may be the ideal choice. However, if you plan to take two or more trips in a year, then it may work out more cost-effective to purchase an annual multi-trip policy.
					</p>

					<h2>Where can I travel to with family travel insurance?</h2>
					<p class="mb-4">Our policies can cover you for trips to the UK, Europe and Worldwide.</p>

					<h2>Do I need to disclose pre-existing medical conditions?</h2>
					<p class="mb-4">
						Yes, every individual on your family policy should declare their pre-existing medical conditions, to ensure full cover is in place.
						<br><br>
						You can find out more information and how to insure your medical conditions by following this link:
						<router-link :to="{name:'medicalConditions'}"> www.covered2go.co.uk/pre-existing-medical-conditions </router-link>
					</p>

					<p class="mb-4">
						<router-link :to="{name:'getQuote'}">Get a quote for family travel insurance today.</router-link>
					</p>

				</div>

				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
				<info-boxes/>
				<!-- <trustpilot-banner/> -->

			</div>
    </div>
  </transition>
</template>

<script>
import InfoBoxes from "../components/common/InfoBoxes";
//import TrustpilotBanner from "@/components/common/trustpilot/TrustpilotBanner";
import InfoButtonOptions from "@/components/common/InfoButtonOptions";

export default {
  name: 'FamilyTravelInsurance',
	data() {
		return {
			countriesCovered: null
		}
	},
  mixins: [],
  components: {
			InfoBoxes,
			//TrustpilotBanner,
			InfoButtonOptions
	}
};
</script>

<style lang="scss" scoped>
	@import 'src/styles/views/info-page';
</style>
